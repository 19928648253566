import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KurzAddressComponent } from './kurz-address.component';



@NgModule({
  declarations: [KurzAddressComponent],
  imports: [
    CommonModule,
  ],
  exports: [KurzAddressComponent]
})
export class KurzAddressModule { }
